export default {
  lineNumberColor: `#545454`,
  lineNumberBgColor: `#fefefe`,
  backgroundColor: `#fefefe`,
  textColor: `#545454`,
  substringColor: `#545454`,
  keywordColor: `#7928a1`,
  attributeColor: `#aa5d00`,
  selectorAttributeColor: `#7928a1`,
  docTagColor: `#545454`,
  nameColor: `#d91e18`,
  builtInColor: `#aa5d00`,
  literalColor: `#aa5d00`,
  bulletColor: `#008000`,
  codeColor: `#545454`,
  additionColor: `#008000`,
  regexpColor: `#d91e18`,
  symbolColor: `#008000`,
  variableColor: `#d91e18`,
  templateVariableColor: `#d91e18`,
  linkColor: `#aa5d00`,
  selectorClassColor: `#d91e18`,
  typeColor: `#aa5d00`,
  stringColor: `#008000`,
  selectorIdColor: `#d91e18`,
  quoteColor: `#696969`,
  templateTagColor: `#545454`,
  deletionColor: `#d91e18`,
  titleColor: `#007faa`,
  sectionColor: `#007faa`,
  commentColor: `#696969`,
  metaKeywordColor: `#545454`,
  metaColor: `#aa5d00`,
  functionColor: `#545454`,
  numberColor: `#aa5d00`,
}
